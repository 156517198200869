import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../../../context/app/AppIndex";
import {updateClientAndAccountBasedOnUrl} from "../../../context/app/AppActions";
import {ReportSettings} from "../../../components/Reports/ReportSettings";

export const ReportSettingsView = () => {
  const appDispatch = useAppDispatch()
  const routerParams = useParams();
  const [searchParams] = useSearchParams()
  useEffect(() => {
    updateClientAndAccountBasedOnUrl(appDispatch, routerParams.clientId, routerParams.accountId)
  }, [])

  return (
    <>
      <Container className="mt-5">
        <ReportSettings
          reportId={routerParams.id}
        />
      </Container>
    </>
  );
};
