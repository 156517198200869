import {destroy, get, post, put} from "./config/base";
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'clients/{clientId}/users/';

export const UserAccess = {
  index: (clientId) =>
    get(`${urlParser(url, clientId)}`)
      .then(handleResponse)
      .catch(handleError),
  put: (clientId, id, payload) =>
    put(`${urlParser(url, clientId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  transferOwnership: (clientId, payload) =>
    post(`${urlParser(url, clientId)}transfer_ownership`, payload)
      .then(handleResponse)
      .catch(handleError),
  byUserId: (clientId, userId) =>
    get(`${urlParser(url, clientId)}${userId}`)
      .then(handleResponse)
      .catch(handleError),
  delete: (clientId, id) =>
    destroy(`${urlParser(url, clientId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
}
