import React from "react";
import {Navigate, Outlet} from "react-router-dom";
import {useAuthState} from "./context/auth/AuthIndex";

const ProtectedRoute = ({children, ...rest}) => {
  const userDetails = useAuthState();

  return Boolean(userDetails.token) ? <Outlet/> : <Navigate to={'/auth/login'}/>
};
export default ProtectedRoute;
