import {useAppDispatch} from "../../../context/app/AppIndex";
import {useParams} from "react-router-dom";
import {useEffect} from "react";
import {updateClientAndAccountBasedOnUrl} from "../../../context/app/AppActions";
import {Container} from "reactstrap";
import {AccountDetail} from "../../../components/Accounts/AccountDetail";

export const AccountView = () => {
  const appDispatch = useAppDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateClientAndAccountBasedOnUrl(appDispatch, routerParams.clientId, routerParams.accountId)
  }, [])

  return (
    <>
      <Container className="mt-2">
        <AccountDetail
          clientId={routerParams.clientId}
          accountId={routerParams.accountId}
        />
      </Container>
    </>
  );
}
