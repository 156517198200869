import React from "react";
import {Badge, Button, Card, CardBody, CardImg} from "reactstrap";
import classNames from "classnames";
import {Link} from "react-router-dom";

export const PlaceholderGrid = ({rows = 3, columns = 4}) => {
  const renderItems = () => {
    const gridRows = [];
    for (let i = 0; i < rows; i++) {
      const gridColumns = [];
      for (let j = 0; j < columns; j++) {
        gridColumns.push(
          <Card key={`grid-${i}-${j}`} className={'m-3'}>
            <CardImg className={'rounded-0'} src={require('../../assets/img/client_placeholder.jpg')}/>
            <CardBody>
              <div className={'d-flex flex-column'}>
                <h3 className={'mb-3'}>---</h3>
                <div className={'d-flex flex-column'}>
                  <div className={'d-flex flex-row justify-content-start'}>
                    <p className={'mb-0'}>
                      <Badge color="" className="badge-dot badge-lg">
                        <i className={classNames({
                          'bg-success': true,
                        })}/>
                      </Badge>
                      ... Active Accounts
                    </p>
                  </div>
                  <div className={'d-flex flex-row justify-content-start'}>
                    <p className={'mb-0'}>
                      <Badge color="" className="badge-dot badge-lg">
                        <i className={classNames({
                          'bg-disabled': true,
                        })}/>
                      </Badge>
                      ... Disabled Accounts
                    </p>
                  </div>
                </div>
                <div className={'align-self-end'}>
                  <Link
                    to={'#'}>
                    <Button color={'primary'} className={'ps-5 pe-5'}>
                      Access
                    </Button>
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        );
      }
      gridRows.push(
        <div className={'d-flex flex-row justify-content-center'} key={`row-${i}`}>{gridColumns}</div>
      );
    }
    return gridRows;
  };
  return (
    <>
      <div className={'position-relative'}>
        {
          renderItems()
        }
        <div className={'position-absolute w-100 h-100 top-0 bg-blur d-flex justify-content-center align-items-center'}>
          <span className="spinner-border spinner-border-lg"/>
        </div>
      </div>
    </>
  )
}
