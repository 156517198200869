import React, {useEffect, useState} from "react";
// react component that copies the given text inside your clipboard
// reactstrap components
import {Container,} from "reactstrap";
// core components
import UserList from "../../../components/Users/UserList";
import {useAppDispatch} from "../../../context/app/AppContext";
import {useParams} from "react-router-dom";
import {updateClientBasedOnUrl} from "../../../context/app/AppActions";

export const UsersView = () => {
  const appDispatch = useAppDispatch()
  const routerParams = useParams();
  useEffect(() => {
    updateClientBasedOnUrl(appDispatch, routerParams.clientId)
  }, [])
  const [createdAuthUser, setCreatedAuthUser] = useState(false);

  return (
    <>
      <Container className="mt-7">
        <UserList
          clientId={routerParams.clientId}
          createdAuthUser={createdAuthUser}
          setCreatedAuthUser={setCreatedAuthUser}
        />
      </Container>
    </>
  );
};
