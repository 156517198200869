export const names = [
  {
    'label': 'GLOBAL',
    'value': 'GLOBAL',
  },
  {
    'label': 'SEARCH_TERMS',
    'value': 'SEARCH_TERMS',
  },
  {
    'label': 'ADVERTISED_PRODUCTS',
    'value': 'ADVERTISED_PRODUCTS',
  },
]
