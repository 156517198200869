import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

import {useNavigate} from "react-router";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// reactstrap components
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Nav,
  Navbar,
  NavbarBrand,
  UncontrolledDropdown,
} from "reactstrap";
import {logout, useAuthDispatch, useAuthState} from "../../context/auth/AuthIndex";
import {Link} from "react-router-dom";
import {useAppState} from "../../context/app/AppIndex";

function AdminNavbar({theme, sidenavOpen, toggleSidenav, logo, ...rest}) {
  const navigate = useNavigate();
  const dispatch = useAuthDispatch();
  const userDetails = useAuthState();
  const appState = useAppState()

  const handleLogout = () => {
    logout(dispatch)
    navigate('/auth/login');
  }

  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          {"navbar-dark": theme === "dark"},
          {"navbar-light": theme === "light"}
        )}
      >

        {logo ? (
          <NavbarBrand {...navbarBrandProps}>
            <img src={require("assets/img/brand/logo.png")} width={'190'}/>
          </NavbarBrand>
        ) : null}
        <Nav className="d-flex flex-fill">
          {!/^\/admin\/clients\/[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/.test(window.location.pathname) && !/^\/admin\/clients\/$/.test(window.location.pathname) ? appState?.accountName : ''}
        </Nav>
        <Nav className="align-items-center ms-auto ms-md-0" navbar>
          <UncontrolledDropdown group>
            <DropdownToggle caret color="secondary">
              {appState?.clientName}
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem href="#pablo" onClick={(e) => {
                e.preventDefault();
                navigate(`/admin/clients/${appState?.clientId}`)
              }}>
                Accounts
              </DropdownItem>
              <DropdownItem href="#pablo" onClick={(e) => {
                e.preventDefault();
                navigate(`/admin/clients/${appState?.clientId}/users`)
              }}>
                Users
              </DropdownItem>
              <DropdownItem href="#pablo" onClick={e => {
                e.preventDefault()
                navigate(`/admin/clients/${appState?.clientId}/accounts/${appState?.accountId}/settings?from=${window.location.pathname}`)
              }}>
                Settings
              </DropdownItem>
              <DropdownItem divider/>
              <DropdownItem onClick={(e) => {
                e.preventDefault();
                navigate(`/admin/clients/`)
              }}>
                Clients
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        <Nav className="align-items-center ms-auto ms-md-0" navbar>
          <UncontrolledDropdown group>
            <DropdownToggle className="nav-link pr-0 d-flex flex-row" color="" tag="a">
              <Media className="">
                    <span className="avatar avatar-sm rounded-circle">
                      <span className="ni ni-single-02"></span>
                    </span>

              </Media>
              <Media className="ms-2 align-self-center d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {userDetails?.user?.name !== '' ? userDetails?.user?.name : userDetails.user.email}
                      </span>
              </Media>
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem className="noti-title" header tag="div">
                <h6 className="text-overflow m-0">Welcome!</h6>
              </DropdownItem>
              <DropdownItem
                href="/admin/user/profile'"
                onClick={(e) => {
                  e.preventDefault();
                  navigate('/admin/user/profile')
                }}>
                <i className="fa fa-user"/>
                <span>Profile</span>
              </DropdownItem>
              <DropdownItem divider/>
              <DropdownItem
                onClick={handleLogout}
              >
                <i className="ni ni-user-run"/>
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {
  },
  sidenavOpen: true,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;
