import React, {useEffect, useState} from "react";
import {Button, Card, CardBody, CardFooter, Input} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {v4 as uuid4} from "uuid";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Clients} from "../../services/api/Clients";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalIndex";

export const ClientSettings = ({clientId, from}) => {
  const navigate = useNavigate()
  const dispatchApiError = useApiErrorDispatch()
  const [client, setClient] = useState({
    name: '',
    status: 'DISABLED',
    client_report_name: '',
  })
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const isNewClient = clientId === null || clientId === undefined
  const modalDispatch = useModalDispatch()


  useEffect(() => {
    if (isNewClient) {
      setClient({...client, id: uuid4()})
      return;
    }
    setLoading(true)
    Clients.get(clientId).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setClient(r?.data)
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }, [clientId]);

  const handleChange = (e) => {
    const {name, value} = e.target;
    setClient(client => ({...client, [name]: value}));
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    if (client.name) {
      setLoading(true)
      let {id, ...clientData} = client;
      Clients.put(id, clientData).then((r) => {
        setLoading(false)
        if (r?.status < 400) {
          if (from !== null && from !== undefined) {
            navigate(from)
          }
          navigate(`/admin/clients/${id}`);
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      })
    }
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      setClient({
        ...client,
        status: 'ACTIVE',
      })
      return
    }
    setClient({
      ...client,
      status: 'DISABLED',
    })
  }


  const handleDelete = () => {
    openModal(modalDispatch, {
      title: 'Delete Client',
      message: 'Do you want to delete this Client?',
      onConfirm: () => {
        Clients.delete(clientId).then((r) => {
          if (r?.status < 400) {
            navigate(`/admin/clients/`);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatch)
        });
      },
      onCancel: () => closeModal(modalDispatch),
      onBackdropClick: () => closeModal(modalDispatch),
      buttonColor: 'danger',
      buttonText: 'Delete',
      buttonIcon: 'fa-trash'
    })
  }

  return (
    <>
      <div className={'view_header'}>
        <div className={'back-button'}>{
          <Link
            to={from !== undefined && from !== null ? from : `/admin/clients/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'title'}>
          <h1>Settings</h1>
        </div>
        <div className={'button_actions'}>
          {
            isNewClient === false ? <Button
              color={'danger'}
              className={'btn-icon-only'}
              onClick={handleDelete}
            >
              <span className="fa-solid fa-trash"/>
            </Button> : <></>
          }
        </div>
      </div>
      <Card className="shadow mt-3">
        <CardBody>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <div className={'d-flex flex-column mb-3 flex-grow-1 me-5'}>
                <h4>Client Name</h4>
                <div className={'d-flex'}>
                  <Input
                    placeholder="Client"
                    type="text"
                    valid={submitted && client.name.length > 0}
                    invalid={submitted && !client.name}
                    name="name"
                    value={client.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="invalid-feedback">
                  Please provide a name
                </div>
              </div>
              <div className={'d-flex flex-column justify-content-evenly mb-3'}>
                <h4>Status</h4>
                <label className="custom-toggle me-1">
                  <input type="checkbox" name="status" checked={client.status === 'ACTIVE'}
                         onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
              </div>
            </div>
            <div className={'d-flex flex-column mb-3 flex-grow-1 me-5'}>
              <h4>Client Report Name</h4>
              <div className={'d-flex'}>
                <Input
                  placeholder="Client"
                  type="text"
                  valid={submitted && client.client_report_name.length > 0}
                  invalid={submitted && !client.client_report_name}
                  name="client_report_name"
                  value={client.client_report_name}
                  onChange={handleChange}
                />
              </div>
              <div className="invalid-feedback">
                Please provide a client report name
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className={'d-flex flex-row justify-content-end'}>
            <Button
              className={'float-right'}
              color={'primary'}
              onClick={handleSubmit}
            >
              {isLoading &&
                <span className="spinner-border spinner-border-sm me-1"/>}
              {isNewClient ? 'Create Client' : 'Save'}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}
