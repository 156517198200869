import React, {useReducer} from 'react';
import {initialState, AppReducer} from './AppReducer';

const AppStateContext = React.createContext();
const AppDispatchContext = React.createContext();

export function useAppState() {
  const context = React.useContext(AppStateContext);
  if (context === undefined) {
    throw new Error('useAppState must be used within a AppProvider');
  }

  return context;
}

export function useAppDispatch() {
  const context = React.useContext(AppDispatchContext);
  if (context === undefined) {
    throw new Error('useAppDispatch must be used within a AppProvider');
  }

  return context;
}

export const ProjectProvider = ({children}) => {
  const [app, dispatch] = useReducer(AppReducer, initialState);

  return (
    <AppStateContext.Provider value={app}>
      <AppDispatchContext.Provider value={dispatch}>
        {children}
      </AppDispatchContext.Provider>
    </AppStateContext.Provider>
  );
};
