import {Card, CardBody} from "reactstrap";
import React from "react";
import classNames from "classnames";

export const AccountPlatformItem = ({account, platformSelected, setPlatformSelected}) => {

  const handleClick = () => {
    setPlatformSelected(account)
  }

  return (
    <>
      <Card className={classNames(
        {
          'm-1 cursor-pointer account_platform_item': true,
          'selected': account.id === platformSelected?.id
        }
      )}>
        <CardBody className={'d-flex flex-column'} onClick={handleClick}>
          <div className={'d-flex justify-content-center flex-grow-1'}>
            <img className={'rounded-1 align-self-center'} src={require('assets/img/account/' + account.img)}
                 width={190} alt={'platform'}/>
          </div>
          <h2>{account.label}</h2>
        </CardBody>
      </Card>
    </>
  )
}
