import {Link, useNavigate} from "react-router-dom";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import React, {useCallback, useEffect, useState} from "react";
import {v4 as uuid4} from "uuid";
import Schedule from "../Schedule/Schedule";
import {Accounts} from "../../services/api/Accounts";
import {accountLocales} from "./AccountLocales";
import {Button, Card, CardBody, CardFooter, Input} from "reactstrap";
import ReactFlagsSelect from "react-flags-select";
import {AccountPlatformItem} from "./AccountPlatformItem";
import {accountPlatforms} from "./AccountPlatforms";
import {AccountData} from "./Data/AccountData";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalIndex";

export const AccountSettings = ({clientId, accountId, from}) => {
  const navigate = useNavigate()
  const dispatchApiError = useApiErrorDispatch()
  const [account, setAccount] = useState({
    name: '',
    status: 'DISABLED',
    credentials: {
      refresh_token: '',
    },
    type: '',
    platformType: '',
    platformName: '',
    data: {},
    locale: '',
    timezone: '',
    schedule: [],
  })
  const [platformSelected, setPlatformSelected] = useState({})
  const [credentials, setCredentials] = useState({})
  const [data, setData] = useState({})
  const [dynamicData, setDynamicData] = useState({})
  const [scheduleComponent, setScheduleComponent] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [submitted, setSubmitted] = useState(false)
  const isNewAccount = accountId === null || accountId === undefined
  const modalDispatch = useModalDispatch()

  useEffect(() => {
    if (isNewAccount) {
      setAccount({...account, id: uuid4()})
      setScheduleComponent(
        <Schedule
          parentCallback={handleScheduleChange}
        />
      )
      return;
    }
    setLoading(true)
    Accounts.get(clientId, accountId).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setAccount(r?.data)
        setCountryBasedOnLocale(r?.data.locale)
        setPlatformSelected(accountPlatforms.find(accountPlatform => accountPlatform.platformType === r?.data.platformType && accountPlatform.name === r?.data.platformName))
        setScheduleComponent(
          <Schedule
            interval={r.data.schedule.interval}
            hour={r.data.schedule.hour}
            minute={r.data.schedule.minute}
            interval_count={r.data.schedule.interval_count}
            timezone={r.data.schedule.timezone}
            locale={r.data.locale}
            parentCallback={handleScheduleChange}
          />
        )
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }, [accountId]);

  const setCountryBasedOnLocale = (locale) => {
    for (const [key, value] of Object.entries(accountLocales)) {
      if (value.secondary === locale) {
        setCountry(key)
      }
    }
  }

  const handleChange = (e) => {
    const {name, value} = e.target;
    setAccount(account => ({...account, [name]: value}));
  }


  const handleScheduleChange = useCallback((schedule) => {
    let copy = schedule
    copy.hour = parseInt(schedule.hour)
    copy.minute = parseInt(schedule.minute)
    setAccount(account => (
        {
          ...account,
          'schedule': copy,
          'timezone': copy.timezone,
        }
      )
    );
  }, []);

  const handleDelete = () => {
    openModal(modalDispatch, {
      title: 'Delete Account',
      message: 'Do you want to delete this Account?',
      onConfirm: () => {
        Accounts.delete(clientId, accountId).then((r) => {
          if (r?.status < 400) {
            navigate(`/admin/clients/${clientId}`);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatch)
        });
      },
      onCancel: () => closeModal(modalDispatch),
      onBackdropClick: () => closeModal(modalDispatch),
      buttonColor: 'danger',
      buttonText: 'Delete',
      buttonIcon: 'fa-trash'
    })
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    setSubmitted(true)

    if (account.name) {
      setLoading(true)
      let {id, client_id, ...accountData} = account;
      accountData.type = platformSelected.type
      accountData.platformType = platformSelected.platform_type
      accountData.platformName = platformSelected.platform_name

      accountData.credentials.platformType = platformSelected.platform_type
      accountData.credentials.platformName = platformSelected.platform_name
      Accounts.put(clientId, id, accountData).then((r) => {
        setLoading(false)
        if (r?.status < 400) {
          if (from !== null && from !== undefined) {
            navigate(from)
          }
          navigate(`/admin/clients/${clientId}`);
        } else {
          apiErrorRaised(dispatchApiError, r?.response)
        }
      })
    }
  }
  const [country, setCountry] = useState("");

  const handleSelectLocale = (code) => {
    setCountry(code)
    setAccount({
      ...account,
      locale: accountLocales[code].secondary
    })
  }
  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      setAccount({
        ...account,
        status: 'ACTIVE',
      })
      return
    }
    setAccount({
      ...account,
      status: 'DISABLED',
    })
  }
  return (
    <>
      <div className={'view_header'}>
        <div className={'back-button'}>{
          <Link
            to={from !== undefined && from !== null ? from : `/admin/clients/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'title'}>
          <h1>Settings</h1>
        </div>
        <div className={'button_actions'}>
          {
            isNewAccount === false ? <Button
              color={'danger'}
              className={'btn-icon-only'}
              onClick={handleDelete}
            >
              <span className="fa-solid fa-trash"/>
            </Button> : <></>
          }
        </div>
      </div>
      <Card className="shadow mt-3">
        <CardBody>
          <div className={'d-flex flex-column'}>
            <div className={'d-flex'}>
              <div className={'d-flex flex-column mb-3 flex-grow-1 me-5'}>
                <h4>Account Name</h4>
                <div className={'d-flex'}>
                  <Input
                    placeholder="Account Name"
                    type="text"
                    valid={submitted && account.name.length > 0}
                    invalid={submitted && !account.name}
                    name="name"
                    value={account.name}
                    onChange={handleChange}
                  />
                </div>
                <div className="invalid-feedback">
                  Please provide a name
                </div>
              </div>
              <div className={'d-flex flex-column justify-content-evenly mb-3'}>
                <h4>Status</h4>
                <label className="custom-toggle me-1">
                  <input type="checkbox" name="status" checked={account.status === 'ACTIVE'}
                         onChange={handleToggle}/>
                  <span className="custom-toggle-slider rounded-circle"/>
                </label>
              </div>
            </div>
            <div className={'d-flex flex-column mb-3'}>
              <h4>Platform</h4>
              <div className={'d-flex flex-column'}>
                <div className={'d-flex'}>
                  {
                    accountPlatforms.map((account, id) => {
                      return (
                        <AccountPlatformItem
                          key={id}
                          account={account}
                          platformSelected={platformSelected}
                          setPlatformSelected={setPlatformSelected}
                        />
                      )
                    })
                  }
                </div>
              </div>
            </div>
            <div className={'d-flex flex-column mb-3'}>
              <h4>Schedule</h4>
              {scheduleComponent}
            </div>
            <div className={'d-flex flex-column mb-3'}>
              <AccountData
                platformSelected={platformSelected}
                account={account}
                setAccount={setAccount}
              />
            </div>
            <div className={'d-flex justify-content-between'}>
              <div className={'d-flex flex-column mt-5 flex-grow-1'}>
                <h3>Locale</h3>
                <ReactFlagsSelect
                  selected={country}
                  className={'pb-0'}
                  searchable={true}
                  placeholder={'Select region'}
                  countries={['ES', 'US', 'GB', 'DE', 'FR', 'PT', 'AT', 'BE', 'NL', 'SE']}
                  customLabels={accountLocales}
                  disabled={!isNewAccount}
                  onSelect={(code) => handleSelectLocale(code)}
                />
              </div>
            </div>
          </div>
        </CardBody>
        <CardFooter>
          <div className={'d-flex flex-row justify-content-end'}>
            <Button
              className={'float-right'}
              color={'primary'}
              onClick={handleSubmit}
            >
              {isLoading &&
                <span className="spinner-border spinner-border-sm me-1"/>}
              {isNewAccount ? 'Create account' : 'Save'}
            </Button>
          </div>
        </CardFooter>
      </Card>
    </>
  )
}
