import React, {useState} from 'react';
import {useAppState} from "../../../context/app/AppIndex";
import {Button} from "reactstrap";
import {useAuthState} from "../../../context/auth/AuthIndex";
import TransferOwnershipModal from "./TransferOwnershipModal";

const TransferOwnershipButton = ({setTransferedOwnership}) => {
  const appState = useAppState()
  const authState = useAuthState()

  const [showModal, setShowModal] = useState(false);

  const onTransferOwnershipClick = () => {
    setShowModal(true);
  }

  return (
    <>
      {
        appState.role === 'owner' || authState.user.role === 'admin' ?
          <Button outline className={'ms-5 float-right'} color="danger" onClick={onTransferOwnershipClick}>
            Transfer ownership
          </Button> : <></>
      }
      <TransferOwnershipModal
        showModal={showModal}
        setShowModal={setShowModal}
        setTransferedOwnership={setTransferedOwnership}
      />
    </>
  );
}

export default TransferOwnershipButton;
