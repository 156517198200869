import {destroy, get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'clients/{clientId}/accounts/';

export const Accounts = {
  index: (clientId, params = null) =>
    get(urlParser(url, clientId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (clientId, id) =>
    get(`${urlParser(url, clientId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (clientId, id, payload) =>
    put(`${urlParser(url, clientId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  delete: (clientId, id) =>
    destroy(`${urlParser(url, clientId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  requestReports: (clientId, id, payload) =>
    post(`${urlParser(url, clientId)}${id}/request_reports`, payload)
      .then(handleResponse)
      .catch(handleError),
}
