import React, {useEffect, useState} from 'react';
import UserItem from "./UserItem.js";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button, Card, Table} from "reactstrap";
import UserInviteModal from "./UserInviteModal";
import TransferOwnershipButton from "./TransferOwnership/TransferOwnershipButton";
import {Link} from "react-router-dom";
import {useAppState} from "../../context/app/AppIndex";
import {UserAccess} from "../../services/api/UserAccess";

const UserList = ({clientId}) => {
  const dispatch = useApiErrorDispatch();
  const appState = useAppState()
  const [showModal, setShowModal] = useState(false);
  const [transferedOwnership, setTransferedOwnership] = useState(false);
  const [deletedUserAccess, setDeletedUserAccess] = useState(false);
  const [isLoading, setLoading] = useState(false)
  const [users, setUsers] = useState([]);
  const [invitedUser, setInvitedUser] = useState(false);

  useEffect(() => {
    setLoading(true);
    UserAccess.index(clientId).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setUsers(r.data)
      } else {
        apiErrorRaised(dispatch, r?.response)
      }
    });
  }, [dispatch, invitedUser, setInvitedUser, setTransferedOwnership, transferedOwnership, deletedUserAccess]);

  const inviteUser = () => {
    setShowModal(true)
  }

  return (
    <>
      <div className={'d-flex flex-row'}>
        <div className={''}>{
          <Link
            to={`/admin/clients/${clientId}`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'d-flex flex-column justify-content-between flex-grow-1 ms-4'}>
          <h1>Users</h1>
          <h3>User Access for {appState.clientName}</h3>
        </div>
        <div className={'d-flex flex-column'}>
          <div className={'d-flex'}>
            <TransferOwnershipButton setTransferedOwnership={setTransferedOwnership}/>
            <Button color={'primary'} onClick={inviteUser}>Add User</Button>
          </div>
        </div>
      </div>
      <Card className="shadow">
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
          <tr>
            <th scope="col">Name</th>
            <th scope="col">Email</th>
            <th scope="col">Role</th>
            <th scope="col"></th>
          </tr>
          </thead>
          <tbody>
          {
            isLoading ?
              <tr>
                <td>
                  <span className="spinner-border spinner-border-lg me-1"/>
                </td>
              </tr>
              :
              users && users.map((user, i) => {
                return (<UserItem key={i} user={user} setDeletedUserAccess={setDeletedUserAccess}/>);
              })
          }
          </tbody>
        </Table>
      </Card>
      <UserInviteModal showModal={showModal} setShowModal={setShowModal} setInvitedUser={setInvitedUser}/>
    </>);
}

export default UserList;
