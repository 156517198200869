import {useAppState} from "../../context/app/AppIndex";
import {UncontrolledAlert} from "reactstrap";
import {useEffect, useState} from "react";

export const HeaderNotifications = () => {
  const appState = useAppState()
  const [state, setState] = useState({
    activeAlert: false,
    noProductsAlert: false,
  })

  const invalidUrl = !/^\/admin\/clients\/[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/.test(window.location.pathname) && !/^\/admin\/companies\/$/.test(window.location.pathname)
  useEffect(() => {
    let newState = {
      activeAlert: false,
      noProductsAlert: false,
    }
    newState.activeAlert = !(appState.accountStatus === 'ACTIVE' || !invalidUrl || !window.location.href.includes('/admin/') || !window.location.href.includes('/auth/'));
    setState(newState)
  }, [appState]);

  return (
    <>
      {
        state.activeAlert ? <UncontrolledAlert color="warning" className={'mb-0'}>
          <span className="fa fa-warning me-2"/>
          <span className="alert-text">
                    <strong>Account Paused - </strong> This account is paused. Go to <strong>Account -> Settings</strong> to activate it
                  </span>
        </UncontrolledAlert> : <></>
      }
    </>
  )
}
