const routes = [
  {
    icon: "fa-solid fa-house text-black",
    path: "/clients/:clientId/accounts/:accountId",
    name: "Home",
    layout: "/admin",
  },
  {
    icon: "fa-solid fa-file text-primary",
    path: "/clients/:clientId/accounts/:accountId/reports",
    name: "Reports",
    layout: "/admin",
  },
];

export default routes;
