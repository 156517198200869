import {destroy, get, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'clients/';

export const Clients = {
  index: (limit, offset, filters) =>
    get(`${url}`, {params: {limit: limit, offset: offset, filters: filters}})
      .then(handleResponse)
      .catch(handleError),
  get: (id) =>
    get(`${url}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (id, payload) =>
    put(`${url}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  delete: (clientId) =>
    destroy(`${urlParser(url)}${clientId}`)
      .then(handleResponse)
      .catch(handleError),
}
