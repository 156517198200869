import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../../../context/app/AppIndex";
import {updateClientAndAccountBasedOnUrl} from "../../../context/app/AppActions";
import {AccountSettings} from "../../../components/Accounts/AccountSettings";

export const AccountSettingsView = () => {
  const appDispatch = useAppDispatch()
  const routerParams = useParams();
  const [searchParams] = useSearchParams()
  useEffect(() => {
    updateClientAndAccountBasedOnUrl(appDispatch, routerParams.clientId, routerParams.accountId)
  }, [])

  return (
    <>
      <Container className="mt-7">
        <AccountSettings
          clientId={routerParams.clientId}
          accountId={routerParams.accountId}
          from={searchParams.get('from')}
        />
      </Container>
    </>
  );
};
