import {Accounts} from "../../services/api/Accounts";
import {UserAccess} from "../../services/api/UserAccess";
import {Clients} from "../../services/api/Clients";

export function selectClient(dispatch, clientId, clientName, userRole, userId) {
  UserAccess.byUserId(clientId, userId).then((r) => {
    if (r?.status < 400) {
      dispatch({
        type: 'CLIENT_SELECTED', payload: {
          clientId: clientId,
          clientName: clientName,
        }
      })
      dispatch({
        type: 'ROLE_UPDATED', payload: {
          userRole: r.data.role
        }
      })
    } else {
      dispatch({
        type: 'CLIENT_SELECTED', payload: {
          clientId: clientId,
          clientName: clientName,
        }
      })
      dispatch({
        type: 'ROLE_UPDATED', payload: {
          userRole: null,
        }
      })
    }
  })
}

export function selectAccount(dispatch, accountId, name, locale, timezone, status, platform, type) {
  dispatch({
    type: 'ACCOUNT_SELECTED',
    payload: {
      id: accountId,
      locale: locale,
      timezone: timezone,
      name: name,
      status: status,
      type: type,
      platform: platform,
    }
  })
}

export function updateClientAndAccountBasedOnUrl(dispatch, clientId, accountId) {
  Clients.get(clientId).then((r) => {
    if (r?.status < 400) {
      dispatch({
        type: 'CLIENT_SELECTED', payload: {
          clientId: clientId,
          clientName: r.data.name,
        }
      })
    }
  });
  if (accountId === undefined || accountId === null) {
    return
  }
  Accounts.get(clientId, accountId).then((r) => {
    if (r?.status < 400) {
      dispatch(
        {
          type: 'UPDATE_CLIENT_AND_ACCOUNT',
          payload: {
            clientId: clientId,
            accountId: accountId,
            accountName: r.data.name,
            accountStatus: r.data.status,
            accountType: r.data.type,
            accountPlatform: r.data.platform,
            locale: r.data.locale,
            timezone: r.data.timezone,
          }
        })
    }
  })
}

export function updateClientBasedOnUrl(dispatch, clientId) {
  dispatch(
    {
      type: 'UPDATE_CLIENT',
      payload: {
        clientId: clientId
      }
    })
}

export function clearProjectState(dispatch) {
  dispatch(
    {
      type: 'CLEAR_APP_STATE'
    }
  )
}
