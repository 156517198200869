import React, {useEffect, useState} from "react";
import {useAppState} from "../../../../context/app/AppContext";
import Select from "react-select";
import {useApiErrorDispatch} from "../../../../context/api_error/ApiErrorContext";
import {Input} from "reactstrap";
import {marketplaces} from "../AmazonAccountMarketplaces";

export const AmazonSellerAccountData = ({account, setAccount}) => {
  const dispatchApiError = useApiErrorDispatch()
  const [state, setState] = useState(
    {
      'credentials': account.credentials,
      'data': account.data,
      'marketplace': account.marketplace,
    }
  )
  const appState = useAppState()

  const handleChange = (e) => {
    const {name, value} = e.target;
    setState({...state, credentials: {...state.credentials, [name]: value}})
  }

  const handleMarketplace = (value) => {
    setState({...state, marketplace: value.value})
  }

  useEffect(() => {
    let mkplace = marketplaces.find((marketplace) => marketplace.id === state.marketplace)
    if (mkplace === undefined) {
      return
    }
    setAccount({
      ...account,
      data: {
        ...account.data,
        marketplace: {
          id: mkplace.id,
          name: mkplace.name,
          endpoint: mkplace.endpoint
        }
      }
    })
  }, [state.marketplace]);

  useEffect(() => {
    setAccount({
      ...account, credentials: {
        ...account.credentials,
        client_id: state.credentials.client_id
      }
    })
  }, [state.credentials.client_id]);

  useEffect(() => {
    setAccount({
      ...account, credentials: {
        ...account.credentials,
        client_secret: state.credentials.client_secret
      }
    })
  }, [state.credentials.client_secret]);

  useEffect(() => {
    setAccount({
      ...account, credentials: {
        ...account.credentials,
        refresh_token: state.credentials.refresh_token
      }
    })
  }, [state.credentials.refresh_token]);

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-column  m-2'}>
          <h4>Marketplace</h4>
          <Select
            defaultValue={marketplaces.find(marketplace => marketplace.id === state.marketplace?.id)}
            onChange={handleMarketplace}
            options={marketplaces}
          />
        </div>
        <div className={'d-flex flex-column'}>
          <div className={'d-flex'}>
            <div className={'d-flex flex-column flex-grow-1 m-2'}>
              <h4>Client Id</h4>
              <div className={'d-flex'}>
                <Input
                  placeholder="Client Id"
                  type="text"
                  name="client_id"
                  value={state.credentials.client_id}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className={'d-flex flex-column flex-grow-1 m-2'}>
              <h4>Client Secret</h4>
              <div className={'d-flex'}>
                <Input
                  placeholder="Client Secret"
                  type="text"
                  name="client_secret"
                  value={state.credentials.client_secret}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>
          <div className={'d-flex flex-column flex-grow-1 m-2'}>
            <h4>Refresh Token</h4>
            <div className={'d-flex'}>
              <Input
                placeholder="Refresh token"
                type="text"
                name="refresh_token"
                value={state.credentials.refresh_token}
                onChange={handleChange}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
