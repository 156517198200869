import React, {useEffect, useState} from 'react';
import {useAppState} from "../../context/app/AppIndex";
import {Input} from "reactstrap";
import {useAuthState} from "../../context/auth/AuthIndex";
import {UserAccess} from "../../services/api/UserAccess";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import DeleteUserAccessButton from "./Delete/DeleteUserAccessButton";

const UserItem = ({user, setDeletedUserAccess}) => {
  const apiErrorDispatch = useApiErrorDispatch()
  const appState = useAppState()
  const authState = useAuthState()
  const [userAccess, setUserAccess] = useState(user)

  const handleChange = (e) => {
    let role = e.target.value
    UserAccess.put(appState.clientId, userAccess.id, {role: role}).then((r) => {
      if (r?.status < 400) {
        setUserAccess({...userAccess, role: role})
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
    })
  }

  useEffect(() => {
    setUserAccess(user)
  }, [user])

  return (
    <>
      <tr>
        <td>
          {userAccess.name}
        </td>
        <td>
          {userAccess.email}
        </td>
        <td>
          {
            authState.user.role === 'admin' || ((appState.role === 'owner' || appState.role === 'admin') && userAccess.role !== 'owner') ?
              <Input
                placeholder="Role"
                className={'form-control'}
                type="select"
                name="role"
                value={userAccess.role}
                onChange={handleChange}
              >
                {authState.user.role === 'admin' ? <option value={'owner'}>Owner</option> : <></>}
                <option value={'admin'}>Admin</option>
                <option value={'colaborator'}>Colaborator</option>
                <option value={'viewer'}>Viewer</option>
              </Input>
              : userAccess.role
          }
        </td>
        <td className={'d-flex justify-content-end'}>
          <DeleteUserAccessButton
            userAccess={userAccess}
            setDeletedUserAccess={setDeletedUserAccess}
          />
        </td>
      </tr>
    </>
  )
}

export default UserItem;
