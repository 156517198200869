import React, {useEffect, useState} from "react";
import {AmazonAdvertisingReportData} from "./AmazonAdvertising/AmazonAdvertisingReportData";
import {AmazonVendorReportData} from "./AmazonVendor/AmazonVendorReportData";
import {AmazonSellerReportData} from "./AmazonSeller/AmazonSellerReportData";

export const ReportData = ({report, setReport}) => {

  const [component, setComponent] = useState()

  useEffect(() => {
    let reportType = report?.type;
    switch (true) {
      case /^McReif\\Reports\\Report\\Domain\\Amazon\\Advertising\\.*Report/.test(reportType):
        setComponent(<AmazonAdvertisingReportData
            report={report}
            setReport={setReport}
          />
        )
        break;
      case /^McReif\\Reports\\Report\\Domain\\Amazon\\Vendor\\.*Report/.test(reportType):
        setComponent(<AmazonVendorReportData
            report={report}
            setReport={setReport}
          />
        )
        break;
      case /^McReif\\Reports\\Report\\Domain\\Amazon\\Seller\\.*Report/.test(reportType):
        setComponent(<AmazonSellerReportData
            report={report}
            setReport={setReport}
          />
        )
        break;
      default:
        setComponent(null)
    }
  }, [report]);

  return (
    <>
      {component}
    </>
  )
}
