export const accountLocales = {
  "GB": {
    "primary": "United Kingdom",
    "secondary": "en_GB"
  },
  "US": {
    "primary": "USA",
    "secondary": "en_US"
  },
  "ES": {
    "primary": "Spain",
    "secondary": "es_ES"
  },
  "DE": {
    "primary": "Germany",
    "secondary": "de_DE"
  },
  "FR": {
    "primary": "France",
    "secondary": "fr_FR"
  },
  "PT": {
    "primary": "Portugal",
    "secondary": "pt_PT"
  },
  "IT": {
    "primary": "Italy",
    "secondary": "it_IT"
  },
  "SE": {
    "primary": "Sweden",
    "secondary": "sv_SE"
  },
  "AT": {
    "primary": "Austria",
    "secondary": "de_AT"
  },
  "BE": {
    "primary": "Belgium",
    "secondary": "en_BE"
  },
  "NL": {
    "primary": "Netherlands",
    "secondary": "nl_NL"
  }
}
