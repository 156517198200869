import {Link, useNavigate} from "react-router-dom";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorContext";
import React, {useEffect, useState} from "react";
import {v4 as uuid4} from "uuid";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useAppState} from "../../context/app/AppIndex";
import {Reports} from "../../services/api/Reports";
import {Button, Card, CardBody, CardFooter} from "reactstrap";
import {closeModal, openModal} from "../../context/modal/ModalActions";
import {useModalDispatch} from "../../context/modal/ModalContext";
import {DateSelector} from "../DatePicker/DateSelector";
import {subDays} from "date-fns";
import {ReportData} from "./Data/ReportData";

export const ReportSettings = ({reportId}) => {
  const modalDispatch = useModalDispatch()
  const navigate = useNavigate()
  const appState = useAppState()
  const dispatchApiError = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [range, setRange] = useState({
    startDate: subDays((new Date()).setHours(0, 0, 0, 0), 7),
    endDate: subDays((new Date()).setHours(0, 0, 0, 0), 1),
  })
  const [report, setReport] = useState()

  const isNewReport = reportId === null || reportId === undefined


  useEffect(() => {
    if (isNewReport) {

      let newType = ''
      switch (appState.accountType) {
        case 'McReif\\Reports\\Account\\Domain\\Amazon\\Advertising\\AmazonAdvertisingAccount':
          newType = 'McReif\\Reports\\Report\\Domain\\Amazon\\Advertising\\AmazonAdvertisingReport'
          break;
        case 'McReif\\Reports\\Account\\Domain\\Amazon\\Vendor\\AmazonVendorAccount':
          newType = 'McReif\\Reports\\Report\\Domain\\Amazon\\Vendor\\AmazonVendorReport'
          break;
        case 'McReif\\Reports\\Account\\Domain\\Amazon\\Seller\\AmazonSellerAccount':
          newType = 'McReif\\Reports\\Report\\Domain\\Amazon\\Seller\\AmazonSellerReport'
          break;
      }
      setReport({
        ...report, id: uuid4(),
        status: 'DISABLED',
        type: newType,
        name: '',
        data: {}
      })
      return;
    }
    setLoading(true)
    Reports.get(appState.clientId, appState.accountId, reportId).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setReport(r?.data)
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }, [reportId]);

  const handleSubmit = (e) => {
    e.preventDefault()

    let correctedType = `${report.name}`.toLowerCase()
      .replace(/_([a-z])/g, (_, group1) => group1.toUpperCase())
      .replace(/^[a-z]/, match => match.toUpperCase());
    switch (appState.accountType) {
      case 'McReif\\Reports\\Account\\Domain\\Amazon\\Advertising\\AmazonAdvertisingAccount':
        correctedType = 'McReif\\Reports\\Report\\Domain\\Amazon\\Advertising\\Types\\AmazonAdvertising' + correctedType + 'Report'
        break;
      case 'McReif\\Reports\\Account\\Domain\\Amazon\\Vendor\\AmazonVendorAccount':
        correctedType = 'McReif\\Reports\\Report\\Domain\\Amazon\\Vendor\\Types\\AmazonVendor' + correctedType + 'Report'
        break;
      case 'McReif\\Reports\\Account\\Domain\\Amazon\\Seller\\AmazonSellerAccount':
        correctedType = 'McReif\\Reports\\Report\\Domain\\Amazon\\Seller\\Types\\AmazonSeller' + correctedType + 'Report'
        break;
    }

    setLoading(true)

    let {id, account_id, data, ...reportData} = report;
    
    Reports.put(appState.clientId, appState.accountId, id, {
      ...reportData,
      data: data,
      type: correctedType
    }).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        navigate(`/admin/clients/${appState.clientId}/accounts/${appState.accountId}/reports`);
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }

  const handleToggle = (e) => {
    const {name, checked} = e.target;
    if (checked) {
      setReport({
        ...report,
        status: 'ACTIVE',
      })
      return
    }
    setReport({
      ...report,
      status: 'DISABLED',
    })
  }

  const handleDelete = () => {
    openModal(modalDispatch, {
      title: 'Delete Report',
      message: 'Do you want to delete this Report?',
      onConfirm: () => {
        Reports.delete(appState.clientId, appState.accountId, report.id).then((r) => {
          if (r?.status < 400) {
            navigate(`/admin/clients/${appState.clientId}/accounts/${appState.accountId}/reports`);
          } else {
            apiErrorRaised(dispatchApiError, r?.response)
          }
          closeModal(modalDispatch)
        });
      },
      onCancel: () => closeModal(modalDispatch),
      onBackdropClick: () => closeModal(modalDispatch),
      buttonColor: 'danger',
      buttonText: 'Delete',
      buttonIcon: 'fa-trash'
    })
  }

  const handleRequestReport = () => {
    setLoading(true);
    Reports.requestReport(appState.clientId, appState.accountId, report.id, range).then((r) => {
      setLoading(false);
      if (r?.status < 400) {

      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <Link
              to={`/admin/clients/${appState.clientId}/accounts/${appState.accountId}/reports`}>
              <Button className={'btn-icon-only text-dark'}>
                <span className={'fa fa-arrow-left'}/>
              </Button>
            </Link>
            <h1 className={'ms-4'}>Settings</h1>
          </div>
          <div className={'d-flex align-items-center'}>
            <div className={'d-flex flex-column justify-content-evenly mb-3'}>
              <h4>Status</h4>
              <label className="custom-toggle me-1">
                <input type="checkbox" name="status" checked={report?.status === 'ACTIVE'}
                       onChange={handleToggle}/>
                <span className="custom-toggle-slider rounded-circle"/>
              </label>
            </div>
            {
              isNewReport === false ?
                <>
                  <div className={'d-flex flex-column ms-3'}>
                    <DateSelector range={range} setRange={setRange} isLoading={isLoading}/>
                    <div className={'d-flex justify-content-end mt-2'}>
                      <Button color={'primary'} onClick={handleRequestReport} className={'flex-grow-1'}>
                        {isLoading &&
                          <span className="spinner-border spinner-border-sm me-1"/>}
                        Request Report
                      </Button>
                    </div>
                  </div>
                  <Button
                    color={'danger'}
                    className={'btn-icon-only ms-5'}
                    onClick={handleDelete}
                  >
                    <span className="fa-solid fa-trash"/>
                  </Button>
                </> : <></>
            }
          </div>
        </div>
        <div className={'mt-4'}>
          <Card className={'flex-grow-1 d-flex flex-column'}>
            <CardBody>
              {
                report && <ReportData
                  report={report}
                  setReport={setReport}
                />
              }
            </CardBody>
            <CardFooter>
              <div className={'d-flex flex-row justify-content-end'}>
                <Button
                  className={'float-right'}
                  color={'primary'}
                  onClick={handleSubmit}
                >
                  {isLoading &&
                    <span className="spinner-border spinner-border-sm me-1"/>}
                  {isNewReport ? 'Create Report' : 'Save'}
                </Button>
              </div>
            </CardFooter>
          </Card>
        </div>
      </div>
    </>
  )
}
