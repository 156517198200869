import {Button, ButtonGroup, Input} from "reactstrap";
import React, {useCallback, useEffect, useState} from "react";
import ScheduleTimezone from "./ScheduleTimezone";
import {useAppState} from "../../context/app/AppIndex";

const Schedule = ({
                    interval = undefined,
                    hour = undefined,
                    minute = undefined,
                    interval_count = undefined,
                    timezone = undefined,
                    locale = undefined,
                    parentCallback
                  }) => {
  const appState = useAppState()
  const [schedule, setSchedule] = useState(
    {
      'interval': interval !== undefined && interval !== null ? interval : 'DAILY',
      'hour': hour !== undefined && hour !== null ? hour.toLocaleString(locale !== undefined ? locale.replace('_', '-') : appState.locale.replace('_', '-'), {minimumIntegerDigits: 2}) : new Date().getHours(),
      'minute': minute !== undefined && minute !== null ? minute.toLocaleString(locale !== undefined ? locale.replace('_', '-') : appState.locale.replace('_', '-'), {minimumIntegerDigits: 2}) : new Date().getMinutes(),
      'interval_count': interval_count !== undefined && interval_count !== null ? interval_count : "1",
      'timezone': timezone !== undefined && timezone !== null ? timezone : 'Europe/Brussels',
    }
  )

  useEffect(() => {
    parentCallback(schedule);
  }, [schedule, parentCallback])


  const handleChangeInterval = (e) => {

    const {value} = e.target;
    setSchedule(schedule => ({...schedule, 'interval': value}));
    if (value === "DAILY") {
      setSchedule(schedule => ({...schedule, 'interval_count': "1"}));
    }
    if (value === "WEEKLY") {
      setSchedule(schedule => ({...schedule, 'interval_count': "MONDAY"}));
    }
  }

  const handleChangeIntervalCount = (e) => {
    const {value} = e.target;
    setSchedule(schedule => ({...schedule, 'interval_count': value}));
  }

  const handleTimeChange = (e) => {
    const {value} = e.target;
    let splitedTime = value.split(':');
    let hour = splitedTime[0];
    let minute = splitedTime[1];
    setSchedule(schedule => ({...schedule, 'hour': hour}));
    setSchedule(schedule => ({...schedule, 'minute': minute}));
  }

  const handleChangeTimezone = useCallback((timezone) => {
    setSchedule(schedule => ({...schedule, 'timezone': timezone}));
  }, [timezone]);

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-column'}>
          <h5>Frequency and time</h5>
          <ButtonGroup className={'w-50'}>
            <Button className={"fe-menu-btn border border-lavender-mist text-dusty-violet"}
                    active={schedule.interval === "HOURLY"} onClick={handleChangeInterval} value={'HOURLY'}>
              Hourly
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
              <div className="vertical-line"></div>
              {/* Línea vertical */}
            </div>
            <Button className={"fe-menu-btn border border-lavender-mist text-dusty-violet"}
                    active={schedule.interval === "DAILY"} onClick={handleChangeInterval} value={'DAILY'}>
              Daily
            </Button>
            <div className='bg-lavender-mist d-flex align-items-center'>
              <div className="vertical-line"></div>
              {/* Línea vertical */}
            </div>
            <Button className={"fe-menu-btn border border-lavender-mist text-dusty-violet"}
                    active={schedule.interval === "WEEKLY"} onClick={handleChangeInterval} value={'WEEKLY'}>
              Weekly
            </Button>
          </ButtonGroup>
        </div>
        <div className={'d-flex flex-row mt-4'}>
          <div className={'w-25 me-3'}>
            <h5>From</h5>
            <Input
              defaultValue={schedule.hour + ":" + schedule.minute}
              id="schedule-time-input"
              type="time"
              onChange={handleTimeChange}
            />
          </div>
          <div className={'w-25 me-3'}>
            <ScheduleTimezone defaultTimezone={schedule.timezone} parentCallback={handleChangeTimezone}/>
          </div>
          {
            schedule.interval === "HOURLY" && <div>
              <h5>Repeat</h5>
              <Input
                type="select"
                className={'form-control'}
                value={schedule.interval_count}
                onChange={handleChangeIntervalCount}
              >
                <option value="1">Every hour</option>
                <option value="2">Every 2 hours</option>
                <option value="3">Every 3 hours</option>
                <option value="4">Every 4 hours</option>
                <option value="6">Every 6 hours</option>
                <option value="8">Every 8 hours</option>
                <option value="12">Every 12 hours</option>
              </Input>
            </div>
          }
          {
            schedule.interval === "WEEKLY" &&
            <div>
              <h5>Repeat</h5>
              <Input
                type="select"
                className={'form-control'}
                onChange={handleChangeIntervalCount}
                value={schedule.interval_count}
              >
                <option value="MONDAY">Every Monday</option>
                <option value="TUESDAY">Every Tuesday</option>
                <option value="WEDNESDAY">Every Wednesday</option>
                <option value="THURSDAY">Every Thursday</option>
                <option value="FRIDAY">Every Friday</option>
                <option value="SATURDAY">Every Saturday</option>
                <option value="SUNDAY">Every Sunday</option>
              </Input>
            </div>
          }
        </div>
      </div>
    </>
  );
}

export default Schedule;
