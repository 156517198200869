import React, {useEffect, useState} from 'react';
import {Button, Row} from "reactstrap";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {selectClient, useAppDispatch, useAppState} from "../../context/app/AppIndex";
import {Link} from "react-router-dom";
import {useAuthState} from "../../context/auth/AuthIndex";
import {Clients} from "../../services/api/Clients";
import {Accounts} from "../../services/api/Accounts";
import {AccountItem} from "./AccountItem";
import Paginator from "../Pagination/Paginator";

const ClientDetail = ({clientId}) => {
  const authState = useAuthState()
  const apiErrorDispatch = useApiErrorDispatch()
  const clientDispatch = useAppDispatch()
  const appState = useAppState()
  const dispatch = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [client, setClient] = useState({})
  const [accounts, setAccounts] = useState([])
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(12)
  const [pagination, setPagination] = useState({})

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  useEffect(() => {
    setLoading(true);
    Clients.get(clientId).then((r) => {
      if (r?.status < 400) {
        setClient(r.data);
        selectClient(clientDispatch, clientId, r.data.name, authState.user.role, authState.user.id,)
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
      setLoading(false);
    });
    loadAccounts()
  }, [clientId, dispatch, apiErrorDispatch]);

  const loadAccounts = () => {
    setLoading(true)
    Accounts.index(clientId).then((r) => {
      if (r?.status < 400) {
        setPagination(r?.data.pagination)
        setAccounts(r.data.accounts.sort(function (a, b) {
            if (a.status === 'ACTIVE') {
              return -1;
            } else {
              return 1
            }
          })
        )
      } else {
        apiErrorRaised(apiErrorDispatch, r?.response)
      }
      setLoading(false);
    });
  }

  useEffect(() => {
    loadAccounts(limit, offset)
  }, [limit, offset])

  if (isLoading) {
    return <div className="error">
      <p>Loading</p>
    </div>
  }

  return (
    <>
      <div className={'view_header'}>
        <div className={'back-button'}>{
          <Link
            to={`/admin/clients/`}>
            <Button className={'btn-icon-only text-dark'}>
              <span className={'fa fa-arrow-left'}/>
            </Button>
          </Link>
        }</div>
        <div className={'title'}>
          <h1>{client.name}</h1>
        </div>
        <div className={'button_actions'}>
          <Link
            to={`/admin/clients/${clientId}/users`}>
            <Button className="btn-icon me-2" color="primary" outline type="button">
                  <span className="btn-inner--icon mr-1 fa-solid fa-user">
                  </span>
              <span className="btn-inner--text">Users</span>
            </Button>
          </Link>
          <Link to={`/admin/clients/${appState.clientId}/accounts/new?from=${window.location.pathname}`}>
            <Button color={'primary'}>Add Account</Button>
          </Link>
        </div>
      </div>
      <Row className={'mt-3'}>
        {
          accounts.map((account, id) => {
            return (
              <AccountItem
                key={id}
                account={account}
              />
            )
          })
        }
      </Row>
      <div className={'mt-3 mb-3'}>
        <Paginator
          pagination={pagination}
          handleClick={handleClick}
          offset={offset}
          limit={limit}
          showRowOptions={false}
        />
      </div>
    </>
  );
}

export default ClientDetail;
