import {destroy, get, post, put} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'clients/{clientId}/accounts/{accountId}/reports/';

export const Reports = {
  index: (clientId, accountId, params = null) =>
    get(urlParser(url, clientId, accountId), {params: params})
      .then(handleResponse)
      .catch(handleError),
  get: (clientId, accountId, id) =>
    get(`${urlParser(url, clientId, accountId)}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (clientId, accountId, id, payload) =>
    put(`${urlParser(url, clientId, accountId)}${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  delete: (clientId, accountId, id) =>
    destroy(`${urlParser(url, clientId, accountId)}${id}`)
      .then(handleResponse)
      .catch(handleError),

  requestReport: (clientId, accountId, id, payload) =>
    post(`${urlParser(url, clientId, accountId)}${id}/request`, payload)
      .then(handleResponse)
      .catch(handleError),
}
