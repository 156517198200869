import React from "react";
import {Container,} from "reactstrap";
import ClientDetail from "../../../components/Clients/ClientDetail";
import {useParams} from "react-router-dom";

const ClientView = () => {
  const routerParams = useParams()

  return (
    <>
      <Container className="mt-7">
        <ClientDetail
          clientId={routerParams.clientId}
        />
      </Container>
    </>
  );
};

export default ClientView;
