import React from 'react';
import {Badge, Button, Card, CardBody, CardImg, Col} from "reactstrap";
import {Link} from "react-router-dom";
import classNames from "classnames";

const ClientItem = ({client}) => {
  return (
    <>
      <Col md={3}>
        <Card>
          <CardImg className={'rounded-0'} src={require('assets/img/client_placeholder.jpg')}/>
          <CardBody>
            <div className={'d-flex flex-column'}>
              <div className={'d-flex justify-content-between align-items-center'}>
                <h3 className={'mb-0'}>{client.name}</h3>
                <Link
                  to={`/admin/clients/${client.id}/settings?from=${window.location.pathname}`}>
                  <Button
                    className={'btn-icon-only'} color={'primary'}
                  >
                    <span className="fa-solid fa-gear"/>
                  </Button>
                </Link>
              </div>
              <div className={'d-flex flex-column'}>
                <div className={'d-flex flex-row justify-content-start'}>
                  <p className={'mb-0'}>
                    <Badge color="" className="badge-dot badge-lg">
                      <i className={classNames({
                        'bg-success': true,
                      })}/>
                    </Badge>
                    {client.resume['ACTIVE'] ?? 0} Active Accounts
                  </p>
                </div>
                <div className={'d-flex flex-row justify-content-start'}>
                  <p className={'mb-0'}>
                    <Badge color="" className="badge-dot badge-lg">
                      <i className={classNames({
                        'bg-disabled': true,
                      })}/>
                    </Badge>
                    {client.resume['DISABLED'] ?? 0} Disabled Accounts
                  </p>
                </div>
              </div>
              <div className={'align-self-end'}>
                <Link
                  to={`/admin/clients/${client.id}`}>
                  <Button color={'primary'} className={'ps-5 pe-5'}>
                    Access
                  </Button>
                </Link>
              </div>
            </div>
          </CardBody>
        </Card>
      </Col>
    </>
  )
}

export default ClientItem;
