export const types = [
  {
    'label': 'SPONSORED PRODUCTS',
    'value': 'SPONSORED_PRODUCTS',
  },
  {
    'label': 'SPONSORED BRANDS',
    'value': 'SPONSORED_BRANDS',
  },
  {
    'label': 'SPONSORED DISPLAY',
    'value': 'SPONSORED_DISPLAY',
  },
]
