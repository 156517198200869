export const marketplaces = [{
  "id": "A2VIGQ35RCS4UG",
  "value": "A2VIGQ35RCS4UG",
  "name": "United Arab Emirates",
  "label": "United Arab Emirates",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "AMEN7PMS3EDWL",
  "value": "AMEN7PMS3EDWL",
  "name": "Belgium",
  "label": "Belgium",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A1PA6795UKMFR9",
  "value": "A1PA6795UKMFR9",
  "name": "Germany",
  "label": "Germany",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A1C3SOZRARQ6R3",
  "value": "A1C3SOZRARQ6R3",
  "name": "Poland",
  "label": "Poland",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "ARBP9OOSHTCHU",
  "value": "ARBP9OOSHTCHU",
  "name": "Egypt",
  "label": "Egypt",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A1RKKUPIHCS9HS",
  "value": "A1RKKUPIHCS9HS",
  "name": "Spain",
  "label": "Spain",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A13V1IB3VIYZZH",
  "value": "A13V1IB3VIYZZH",
  "name": "France",
  "label": "France",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A1F83G8C2ARO7P",
  "value": "A1F83G8C2ARO7P",
  "name": "Great Britain",
  "label": "Great Britain",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A21TJRUUN4KGV",
  "value": "A21TJRUUN4KGV",
  "name": "India",
  "label": "India",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "APJ6JRA9NG5V4",
  "value": "APJ6JRA9NG5V4",
  "name": "Italy",
  "label": "Italy",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A1805IZSGTT6HS",
  "value": "A1805IZSGTT6HS",
  "name": "Netherlands",
  "label": "Netherlands",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A17E79C6D8DWNP",
  "value": "A17E79C6D8DWNP",
  "name": "Saudi Arabia",
  "label": "Saudi Arabia",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A2NODRKZP88ZB9",
  "value": "A2NODRKZP88ZB9",
  "name": "Sweden",
  "label": "Sweden",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A33AVAJ2PDY3EV",
  "value": "A33AVAJ2PDY3EV",
  "name": "Turkey",
  "label": "Turkey",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "AE08WJ6YKNBMC",
  "value": "AE08WJ6YKNBMC",
  "name": "South Africa",
  "label": "South Africa",
  "endpoint": "https://sellingpartnerapi-eu.amazon.com"
}, {
  "id": "A39IBJ37TRP1C6",
  "value": "A39IBJ37TRP1C6",
  "name": "Australia",
  "label": "Australia",
  "endpoint": "https://sellingpartnerapi-fe.amazon.com"
}, {
  "id": "A1VC38T7YXB528",
  "value": "A1VC38T7YXB528",
  "name": "Japan",
  "label": "Japan",
  "endpoint": "https://sellingpartnerapi-fe.amazon.com"
}, {
  "id": "A19VAU5U5O7RUS",
  "value": "A19VAU5U5O7RUS",
  "name": "Singapore",
  "label": "Singapore",
  "endpoint": "https://sellingpartnerapi-fe.amazon.com"
}, {
  "id": "ATVPDKIKX0DER",
  "value": "ATVPDKIKX0DER",
  "name": "United States",
  "label": "United States",
  "endpoint": "https://sellingpartnerapi-na.amazon.com"
}, {
  "id": "A2Q3Y263D00KWC",
  "value": "A2Q3Y263D00KWC",
  "name": "Brazil",
  "label": "Brazil",
  "endpoint": "https://sellingpartnerapi-na.amazon.com"
}, {
  "id": "A2EUQ1WTGCTBG2",
  "value": "A2EUQ1WTGCTBG2",
  "name": "Canada",
  "label": "Canada",
  "endpoint": "https://sellingpartnerapi-na.amazon.com"
}, {
  "id": "A1AM78C64UM0Y8",
  "value": "A1AM78C64UM0Y8",
  "name": "Mexico",
  "label": "Mexico",
  "endpoint": "https://sellingpartnerapi-na.amazon.com"
}]
