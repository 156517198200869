import {Button, Col, Row} from "reactstrap";
import DateRangePicker from "react-date-range/dist/components/DateRangePicker";
import {es} from "date-fns/locale";

import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import {useEffect, useState} from "react";

import {useNavigate} from "react-router-dom";
import {useAppState} from "../../context/app/AppIndex";

export const DateSelector = ({isLoading, range, setRange, ...props}) => {
  const [collapse, setCollapse] = useState(true);
  const [focusedRange, setFocusedRange] = useState([0, 0]);
  const navigate = useNavigate()
  const appState = useAppState()

  const [state, setState] = useState({
    dates: {
      startDate: range.startDate,
      endDate: range.endDate,
      key: 'dates'
    }
  });
  const [ranges, setRanges] = useState([state.dates]);

  const datesToText = () => {
    let text = '';
    ranges.forEach((range) =>
      text += new Intl.DateTimeFormat(appState.locale.replace('_', '-')).format(range.startDate)
        + " - " + new Intl.DateTimeFormat(appState.locale.replace('_', '-')).format(range.endDate) + " "
    )
    return text.slice(0, -1);
  }

  useEffect(() => {
    setRanges([state.dates])
  }, [state])

  const handleCollapse = () => {
    setCollapse(!collapse);
  }

  const handleSaveDates = () => {
    setRange({
      startDate: state.dates.startDate,
      endDate: state.dates.endDate,
    });
    setCollapse(true)
  }


  return (
    <>
      <Row {...props}>
        <Col>
          <div className="date_selector">
            <Button className="btn-icon float-end" color="primary" outline type="button" onClick={handleCollapse}
                    disabled={isLoading}>
                  <span className="btn-inner--icon me-1">
                    <i className="fas fa-calendar"/>
                  </span>
              <span className="btn-inner--text">{datesToText()}</span>
              <span className="btn-inner--icon me-1">
                    <i className={(collapse ? "ni ni-bold-down" : "ni ni-bold-up")}/>
              </span>
            </Button>
            {
              !collapse ? <div className="date_selector_box mt-5 d-flex flex-column bg-white">
                <DateRangePicker
                  locale={es}
                  focusedRange={focusedRange}
                  onRangeFocusChange={setFocusedRange}
                  onChange={item => setState({...state, ...item})}
                  ranges={ranges}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  direction="horizontal"
                />
                <div className={'d-flex flex-row align-items-center m-3 justify-content-end'}>
                  <Button outline color={'danger'} onClick={handleCollapse}>Cancel</Button>
                  <Button color={'primary'} onClick={handleSaveDates}>Select</Button>
                </div>
              </div> : <></>
            }
          </div>
        </Col>
      </Row>
    </>
  )
}
