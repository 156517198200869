import React from "react";
import {Container,} from "reactstrap";
import {ClientsList} from "../../../components/Clients/ClientsList";

const ClientsView = () => {
  return (
    <>
      <Container className="mt-7">
        <ClientsList/>
      </Container>
    </>
  );
};

export default ClientsView;
