import React from 'react';
import {useAppState} from "../../../context/app/AppIndex";
import {Button} from "reactstrap";
import {useAuthState} from "../../../context/auth/AuthIndex";
import {closeModal, openModal} from "../../../context/modal/ModalActions";
import {UserAccess} from "../../../services/api/UserAccess";
import {apiErrorRaised} from "../../../context/api_error/ApiErrorActions";
import {useModalDispatch} from "../../../context/modal/ModalIndex";
import {useApiErrorDispatch} from "../../../context/api_error/ApiErrorIndex";

const DeleteUserAccessButton = ({userAccess, setDeletedUserAccess}) => {
  const appState = useAppState()
  const authState = useAuthState()
  const modalDispatcher = useModalDispatch()
  const apiErrorDispatch = useApiErrorDispatch()


  const handleClickDeleteUserAccess = () => {
    openModal(modalDispatcher, {
      title: 'Delete User Access',
      message: 'Do you want to delete this user access?',
      onConfirm: () => {
        UserAccess.delete(appState.clientId, userAccess.id).then((r) => {
          if (r?.status < 400) {
            setDeletedUserAccess(true)
          } else {
            apiErrorRaised(apiErrorDispatch, r?.response)
          }
          closeModal(modalDispatcher)
        });
      },
      onCancel: () => closeModal(modalDispatcher),
      onBackdropClick: () => closeModal(modalDispatcher),
      buttonColor: 'danger',
      buttonText: 'Delete',
      buttonIcon: 'fa-trash'
    })
  }

  return (
    <>
      {
        authState.user.role === 'admin' || ((appState.role === 'owner' || appState.role === 'admin') && userAccess.role !== 'owner') ?
          <Button
            color={'danger'}
            className={'btn-icon-only'}
            onClick={handleClickDeleteUserAccess}
          >
            <span className="fa-solid fa-trash"/>
          </Button>
          : ''
      }
    </>
  );
}

export default DeleteUserAccessButton;
