import {DateSelector} from "../DatePicker/DateSelector";
import {useState} from "react";
import {subDays} from "date-fns";
import {Button} from "reactstrap";
import {useAppState} from "../../context/app/AppIndex";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {Accounts} from "../../services/api/Accounts";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";

export const AccountDetail = () => {
  const appState = useAppState()
  const dispatchApiError = useApiErrorDispatch()
  const [isLoading, setLoading] = useState(false)
  const [range, setRange] = useState({
    startDate: subDays((new Date()).setHours(0, 0, 0, 0), 7),
    endDate: subDays((new Date()).setHours(0, 0, 0, 0), 1),
  })

  const handleClick = () => {
    setLoading(true);
    Accounts.requestReports(appState.clientId, appState.accountId, range).then((r) => {
      setLoading(false);
      if (r?.status < 400) {

      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }

  return (
    <>
      <div>
        <DateSelector range={range} setRange={setRange} isLoading={isLoading}/>
        <div className={'d-flex justify-content-end mt-2'}>
          <Button color={'primary'} onClick={handleClick}>
            {isLoading &&
              <span className="spinner-border spinner-border-sm me-1"/>}
            Request Reports
          </Button>
        </div>
      </div>
    </>
  )
}
