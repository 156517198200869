let app = localStorage.getItem("app")
  ? JSON.parse(localStorage.getItem("app"))
  : {};

export const initialState = {
  clientId: app?.clientId,
  clientName: app?.clientName,
  accountId: app?.accountId,
  accountName: app?.accountName,
  accountStatus: app?.accountStatus,
  accountType: app?.accountType,
  accountPlatform: app?.accountPlatform,
  locale: app?.locale,
  timezone: app?.timezone,
  role: app?.role,
};

const save = () => {
  localStorage.setItem('app', JSON.stringify(app));
};

export const AppReducer = (initialState, action) => {
  switch (action.type) {
    case "CLIENT_SELECTED":
      app = {
        ...app,
        clientId: action.payload.clientId,
        clientName: action.payload.clientName,
      }
      break;
    case "ROLE_UPDATED":
      app = {
        ...app,
        role: action.payload.userRole
      }
      break;
    case "ACCOUNT_SELECTED":
      app = {
        ...app,
        accountId: action.payload.id,
        accountName: action.payload.name,
        accountStatus: action.payload.status,
        accountType: action.payload.type,
        accountPlatform: action.payload.platform,
        locale: action.payload.locale,
        timezone: action.payload.timezone,
      }
      break;
    case "UPDATE_CLIENT_AND_ACCOUNT":
      app = {
        ...app,
        clientId: action.payload.clientId,
        accountId: action.payload.accountId,
        accountName: action.payload.accountName,
        accountStatus: action.payload.accountStatus,
        accountType: action.payload.accountType,
        accountPlatform: action.payload.accountPlatform,
        locale: action.payload.locale,
        timezone: action.payload.timezone,
      }
      break;
    case "UPDATE_CLIENT":
      app = {
        ...app,
        clientId: action.payload.clientId,
        accountId: '',
        locale: '',
      }
      break;
    case "CLEAR_APP_STATE":
      app = {
        ...app,
        clientId: null,
        accountId: null,
        locale: null,
        accountStatus: null,
      }
      break;
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
  save();
  return app;
};
