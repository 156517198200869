import React, {useEffect, useState} from "react";
import {Clients} from "../../services/api/Clients";
import {apiErrorRaised} from "../../context/api_error/ApiErrorActions";
import {useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button, Input, Row} from "reactstrap";
import ClientItem from "./ClientItem";
import Paginator from "../Pagination/Paginator";
import {PlaceholderGrid} from "../PlaceholderGrid/PlaceholderGrid";
import {Link} from "react-router-dom";

export const ClientsList = ({}) => {
  const [clients, setClients] = useState([])
  const [isLoading, setLoading] = useState(false)
  const dispatchApiError = useApiErrorDispatch()
  const [pagination, setPagination] = useState({})
  const [searchTerm, setSearchTerm] = useState('')
  const [filters, setFilters] = useState([])
  const [debounceTimer, setDebounceTimer] = useState(null);

  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(12)

  useEffect(() => {
    loadClients(limit, offset)
  }, [limit, offset, filters])

  const loadClients = () => {
    setLoading(true)
    Clients.index(limit, offset, filters).then((r) => {
      setLoading(false)
      if (r?.status < 400) {
        setClients(r?.data.clients)
        setPagination(r?.data.pagination)
      } else {
        setLoading(false)
        apiErrorRaised(dispatchApiError, r?.response)
      }
    })
  }

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const handleSearchChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);

    clearTimeout(debounceTimer);
    const newTimer = setTimeout(() => {
      setFilters([{field: 'name', operator: 'CONTAINS', value: term}])
      setOffset(0)
    }, 500);
    setDebounceTimer(newTimer);
  };

  return (<>
    <div className={'view_header'}>
      <div className={'title'}>
        <h1>Welcome</h1>
      </div>
      <div className={'button_actions'}>
        <div className={'d-flex me-2'}>
          <Input
            placeholder="Search"
            type="text"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        <Link
          to={`/admin/clients/new`}>
          <Button color={'primary'}
          >
            New Client
          </Button>
        </Link>
      </div>
    </div>
    <Row className={'mt-3'}>
      {isLoading ? <PlaceholderGrid/> : clients.map((client, id) => {
        return (<ClientItem
          key={id}
          client={client}
        />)
      })}
    </Row>
    <div className={'mt-3 mb-3'}>
      <Paginator
        pagination={pagination}
        handleClick={handleClick}
        offset={offset}
        limit={limit}
        showRowOptions={false}
      />
    </div>
  </>)
}
