import React, {useEffect, useState} from "react";
import {AmazonAdvertisingAccountData} from "./AmazonAdvertising/AmazonAdvertisingAccountData";
import {AmazonVendorAccountData} from "./AmazonVendor/AmazonVendorAccountData";
import {AmazonSellerAccountData} from "./AmazonSeller/AmazonSellerAccountData";

export const AccountData = ({account, setAccount, platformSelected}) => {

  const [component, setComponent] = useState()

  useEffect(() => {
    switch (platformSelected?.id) {
      case 'amazon_advertising':
        setComponent(<AmazonAdvertisingAccountData
            account={account}
            setAccount={setAccount}
          />
        )
        break;
      case 'amazon_vendor':
        setComponent(<AmazonVendorAccountData
            account={account}
            setAccount={setAccount}
          />
        )
        break;
      case 'amazon_seller':
        setComponent(<AmazonSellerAccountData
            account={account}
            setAccount={setAccount}
          />
        )
        break;
      default:
        setComponent(null)
    }
  }, [platformSelected, account]);

  return (
    <>
      <h4>Account Data</h4>
      {component}
    </>
  )
}
