import Select from "react-select";
import React, {useEffect, useState} from "react";
import {names} from "./AmazonAdvertisingReportDataNames";
import {types} from "./AmazonAdvertisingReportDataTypes";

export const AmazonAdvertisingReportData = ({report, setReport}) => {

  const [state, setState] = useState(
    {
      'name': report.name,
      'data': report.data,
    }
  )

  const handleType = (value) => {
    setState({
      ...state,
      name: `${state?.data?.reportInfo?.name}_${value.value}`,
      data: {
        ...state.data,
        reportInfo: {
          ...state.data.reportInfo,
          type: value.value
        }
      }
    })
  }

  useEffect(() => {
    setReport({
      ...report,
      name: state.name,
      data: {
        ...report.data,
        reportInfo: state.data.reportInfo
      }
    })
  }, [state]);

  const handleName = (value) => {
    setState({
      ...state,
      name: `${value.value}_${state.data.reportInfo.type}`,
      data: {
        ...state.data,
        reportInfo: {
          ...state.data.reportInfo,
          name: value.value
        }
      }
    })
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex'}>
        </div>
        <div className={'d-flex'}>
          <div className={'d-flex flex-column mb-3 me-5'}>
            <h4>Report Type</h4>
            <div className={'d-flex'}>
              <Select
                isSearchable={false}
                value={types.find(type => type.value === state.data.reportInfo?.type)}
                onChange={handleType}
                options={types}
              />
            </div>
          </div>
          <div className={'d-flex flex-column mb-3 me-5'}>
            <h4>Report Name</h4>
            <div className={'d-flex'}>
              <Select
                isSearchable={false}
                value={names.find(name => name.value === state.data.reportInfo?.name)}
                onChange={handleName}
                options={names}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
