import React, {useEffect, useState} from 'react';
import {ProjectProvider} from "./context/app/AppContext";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import AuthLayout from "./layouts/AuthLayout";
import {AuthProvider} from "./context/auth/AuthContext";
import ReactBSAlert from "react-bootstrap-sweetalert";
import {apiErrorClosed} from "./context/api_error/ApiErrorActions";
import {useApiErrorDispatch, useApiErrorState} from "./context/api_error/ApiErrorIndex";
import {ModalProvider} from "./context/modal/ModalContext";
import Login from "./components/Login/Login";
import SignUpThanks from "./components/SignUp/SignUpThanks";
import SignUp from "./components/SignUp/SignUp";
import ForgotPasswordThanks from "./components/ForgotPassword/ForgotPasswordThanks";
import ForgotPassword from "./components/ForgotPassword/ForgotPassword";
import ResetPasswordThanks from "./components/ResetPassword/ResetPasswordThanks";
import ResetPasswordView from "./views/pages/resetPassword/ResetPasswordView";
import {GlobalLayout} from "./layouts/GlobalLayout";
import ConfirmationView from "./views/pages/confirmation/ConfirmationView";
import ClientsView from "./views/pages/clients/ClientsView";
import ClientView from "./views/pages/clients/ClientView";
import AdminLayout from "./layouts/AdminLayout";
import {UsersView} from "./views/pages/users/UsersView";
import {UserProfileView} from "./views/pages/users/UserProfileView";
import {HeaderNotifications} from "./components/Notification/HeaderNotifications";
import './services/internationalization/i18'
import {NotFoundError} from "./views/pages/NotFoundError";
import {ClientSettingsView} from "./views/pages/clients/ClientSettingsView";
import {AccountSettingsView} from "./views/pages/accounts/AccountSettingsView";
import {AccountView} from "./views/pages/accounts/AccountView";
import {ReportsView} from "./views/pages/reports/ReportsView";
import {ReportSettingsView} from "./views/pages/reports/ReportSettingsView";

const App = () => {
  const error = useApiErrorState()
  const dispatch = useApiErrorDispatch();

  const [alert, setAlert] = useState(false)

  useEffect(() => {
    if (error.visible) {
      setAlert(
        <ReactBSAlert
          danger
          style={{display: "block", marginTop: "-100px"}}
          title={error.statusText}
          onConfirm={() => apiErrorClosed(dispatch)}
          onCancel={() => apiErrorClosed(dispatch)}
          confirmBtnBsStyle="danger"
          confirmBtnText="Back"
          btnSize=""
        >
          {error.message}
        </ReactBSAlert>
      );
    } else {
      setAlert(null)
    }
  }, [error, dispatch])

  return (
    <>
      {alert}
      <AuthProvider>
        <ProjectProvider>
          <ModalProvider>
            <BrowserRouter>
              <HeaderNotifications/>
              <Routes>
                <Route index path="/" element={<Login/>}/>
                <Route path="/auth" element={<AuthLayout/>}>
                  <Route path='/auth/login' element={<Login/>}/>
                  <Route path='/auth/activation/:userId/:confirmation' element={<ConfirmationView/>}/>
                  <Route path='/auth/register/:invitation?' element={<SignUp/>}/>
                  <Route path='/auth/register/thanks' element={<SignUpThanks/>}/>
                  <Route path='/auth/forgot_password' element={<ForgotPassword/>}/>
                  <Route path='/auth/forgot_password/thanks' element={<ForgotPasswordThanks/>}/>
                  <Route path='/auth/reset_password/:userId/:confirmation' element={<ResetPasswordView/>}/>
                  <Route path='/auth/reset_password/thanks' element={<ResetPasswordThanks/>}/>
                </Route>
                <Route element={<ProtectedRoute path="/admin"/>}>
                  <Route path="/admin" element={<GlobalLayout/>}>
                    <Route path="clients" element={<ClientsView/>}/>
                    <Route path="clients/:clientId" element={<ClientView/>}/>
                    <Route path="clients/:clientId/settings" element={<ClientSettingsView/>}/>
                    <Route path="clients/new" element={<ClientSettingsView/>}/>
                    <Route path="clients/:clientId/accounts/:accountId/settings" element={<AccountSettingsView/>}/>
                    <Route path="clients/:clientId/accounts/new" element={<AccountSettingsView/>}/>
                    <Route path="clients/:clientId/users" element={<UsersView/>}/>
                    <Route path="user/profile" element={<UserProfileView/>}/>
                  </Route>
                  <Route path="/admin" element={<AdminLayout/>}>
                    <Route path="clients/:clientId/accounts/:accountId" element={<AccountView/>}/>
                    <Route path="clients/:clientId/accounts/:accountId/reports" element={<ReportsView/>}/>
                    <Route path="clients/:clientId/accounts/:accountId/reports/:id/settings"
                           element={<ReportSettingsView/>}/>
                    <Route path="clients/:clientId/accounts/:accountId/reports/new" element={<ReportSettingsView/>}/>
                  </Route>
                </Route>
                <Route path="*" element={<NotFoundError/>}/>
              </Routes>
            </BrowserRouter>
          </ModalProvider>
        </ProjectProvider>
      </AuthProvider>
    </>
  )
}

export default App;
