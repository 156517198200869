import React from "react";
import {Button, Card, CardBody, CardHeader} from "reactstrap";
import {useNavigate} from "react-router";
import classNames from "classnames";
import {Link} from "react-router-dom";
import {selectAccount, useAppDispatch, useAppState} from "../../context/app/AppIndex";
import {accountPlatforms} from "../Accounts/AccountPlatforms";

export const AccountItem = ({account}) => {
  const navigate = useNavigate()
  const appDispatch = useAppDispatch()
  const appState = useAppState()
  const handleClick = () => {
    selectAccount(appDispatch, account.id, account.name, account.locale, account.timezone, account.properties,
      account.status, account.platform, account.type)
    navigate(`/admin/clients/${appState.clientId}/accounts/${account.id}`)
  }

  return (
    <>
      <div className={'col-md-3 d-flex align-items-stretch'}>
        <Card className={classNames({
          'account_card_disabled': account.status === 'DISABLED',
          'account_card': true,
        })}>
          <CardHeader className={classNames({
            'account_card_disabled': account.status === 'DISABLED',
            'pt-3 pb-1 ps-4': true,
          })}>
            <div className={'d-flex justify-content-between align-items-center'}>
              <h3 className={'mb-0'}>{account.name}</h3>
              <Link
                to={`/admin/clients/${appState.clientId}/accounts/${account.id}/settings?from=${window.location.pathname}`}>
                <Button
                  className={'btn-icon-only'} color={'primary'}
                >
                  <span className="fa-solid fa-gear"/>
                </Button>
              </Link>
            </div>
          </CardHeader>
          <CardBody className="d-flex flex-column">
            <div className={'d-flex justify-content-center flex-grow-1'}>
              <img className={'rounded-1 align-self-center'}
                   src={require('assets/img/account/' + accountPlatforms.find((acc) => acc.platform_name === account.platform_name && acc.platform_type === account.platform_type).img)}
              />
            </div>
            <div className="d-flex flex-grow-1 justify-content-end">
              <div className={'d-flex justify-content-end'}>
                <Button
                  color={account.status === 'DISABLED' ? 'account_card_button_disabled' : 'primary'}
                  className={classNames({
                    'btn-primary': true,
                    'account_card_button_disabled': account.status === 'DISABLED',
                    'mt-auto': true,
                  })}
                  onClick={handleClick}>
                  Access
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};
