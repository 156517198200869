export const names = [
  {
    'label': 'Tráfico y Ventas',
    'value': 'SALES_AND_TRAFFIC',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Devoluciones',
    'value': 'RETURNS',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Devoluciones FBA',
    'value': 'RETURNS_FBA',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Inventario FBA Snapshot',
    'value': 'INVENTORY_FBA_SNAPSHOT',
    'async': true,
    'snapshot': true,
  },
]
