import {Button, Card, CardBody} from "reactstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {useAppState} from "../../context/app/AppIndex";
import classNames from "classnames";

export const ReportItem = ({report}) => {
  const appState = useAppState()
  const [component, setComponent] = useState()

  useEffect(() => {
    let reportType = report?.type;
    switch (true) {
      case /^McReif\\Reports\\Report\\Domain\\Amazon\\Advertising\\.*Report/.test(reportType):
        setComponent(
          <div className={'d-flex flex-column'}>
            <h2
              className={classNames({'text-muted': report.status === 'DISABLED'})}>{report.data.reportInfo?.name}</h2>
            <div className={'d-flex'}>
              <h5 className={'me-2 text-muted text-uppercase'}>{report.data.reportInfo?.type} </h5>
            </div>
          </div>
        )
        break;
      case /^McReif\\Reports\\Report\\Domain\\Amazon\\Vendor\\.*Report/.test(reportType):
        setComponent(
          <div className={'d-flex flex-column'}>
            <h2
              className={classNames({'text-muted': report.status === 'DISABLED'})}>{report?.name}
            </h2>
          </div>
        )
        break;
      case /^McReif\\Reports\\Report\\Domain\\Amazon\\Seller\\.*Report/.test(reportType):
        setComponent(
          <div className={'d-flex flex-column'}>
            <h2
              className={classNames({'text-muted': report.status === 'DISABLED'})}>{report?.name}
            </h2>
          </div>
        )
        break;
      default:
        setComponent(null)
    }
  }, [report]);

  return (
    <>
      <Card>
        <CardBody className={'d-flex justify-content-between'}>
          <div className={'d-flex flex-column'}>
            {component}
          </div>
          <Link
            to={`/admin/clients/${appState.clientId}/accounts/${appState.accountId}/reports/${report.id}/settings`}>
            <Button className={classNames({
              'report_card_button_disabled': report.status === 'DISABLED'
            })} color={'secondary'}>
              <span className="fa-solid fa-arrow-right"/>
            </Button>
          </Link>
        </CardBody>
      </Card>
    </>
  )
}
