export const accountPlatforms = [
  {
    id: 'amazon_vendor',
    platform_name: 'amazon',
    platform_type: 'vendor',
    type: 'McReif\\Reports\\Account\\Domain\\Amazon\\Vendor\\AmazonVendorAccount',
    img: 'amazon_vendor.png',
    label: 'Amazon Vendor',
  },
  {
    id: 'amazon_seller',
    platform_name: 'amazon',
    platform_type: 'seller',
    type: 'McReif\\Reports\\Account\\Domain\\Amazon\\Seller\\AmazonSellerAccount',
    img: 'amazon_seller.png',
    label: 'Amazon Seller',
  },
  {
    id: 'amazon_advertising',
    platform_name: 'amazon',
    platform_type: 'advertising',
    type: 'McReif\\Reports\\Account\\Domain\\Amazon\\Advertising\\AmazonAdvertisingAccount',
    img: 'amazon_advertising.png',
    label: 'Amazon Advertising',
  }
]
