import React from "react";
// react library for routing
import {Outlet, useLocation} from "react-router-dom";
// core components
import AdminNavbar from "../components/Navbars/AdminNavbar";
import {useAppState} from "../context/app/AppIndex";

export function GlobalLayout() {
  const [sidenavOpen, setSidenavOpen] = React.useState(true);
  const location = useLocation();
  const appState = useAppState()
  const mainContentRef = React.useRef(null);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContentRef.current.scrollTop = 0;
  }, [location]);

  // toggles collapse between mini sidenav and normal
  const toggleSidenav = (e) => {
    if (document.body.classList.contains("g-sidenav-pinned")) {
      document.body.classList.remove("g-sidenav-pinned");
      document.body.classList.add("g-sidenav-hidden");
    } else {
      document.body.classList.add("g-sidenav-pinned");
      document.body.classList.remove("g-sidenav-hidden");
    }
    setSidenavOpen(!sidenavOpen);
  };
  const getNavbarTheme = () => {
    return 'light'
  };

  return (
    <>
      <div className="main-content" ref={mainContentRef}>
        <AdminNavbar
          theme={getNavbarTheme()}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          logo={{
            innerLink: `/admin/clients/${appState?.clientId ?? ''}`,
            imgSrc: "assets/img/brand/feedest_purple.svg",
            imgAlt: "logo",
          }}
        />
        <Outlet/>
      </div>
    </>
  );
}
