import React, {useEffect} from "react";
import {Container,} from "reactstrap";
import {useParams, useSearchParams} from "react-router-dom";
import {useAppDispatch} from "../../../context/app/AppIndex";
import {updateClientAndAccountBasedOnUrl} from "../../../context/app/AppActions";
import {ClientSettings} from "../../../components/Clients/ClientSettings";

export const ClientSettingsView = () => {
  const appDispatch = useAppDispatch()
  const routerParams = useParams();
  const [searchParams] = useSearchParams()

  return (
    <>
      <Container className="mt-7">
        <ClientSettings
          clientId={routerParams.clientId}
          from={searchParams.get('from')}
        />
      </Container>
    </>
  );
};
