import Select from "react-select";
import React, {useEffect, useState} from "react";
import {names} from "./AmazonVendorReportDataNames";

export const AmazonVendorReportData = ({report, setReport}) => {
  const [state, setState] = useState(
    {
      'name': report.name,
    }
  )

  useEffect(() => {
    let reportData = names.find(name => name.value === state.name)
    setReport({
      ...report,
      name: state.name,
      data: {
        reportInfo: {
          async: reportData?.async,
          snapshot: reportData?.snapshot,
        }
      }
    })
  }, [state]);

  const handleName = (value) => {
    setState({
      ...state,
      name: value.value,
    })
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <h4>Report Name</h4>
        <Select
          isSearchable={false}
          value={names.find(name => name.value === state.name)}
          onChange={handleName}
          options={names}
        />
      </div>
    </>
  )
}
