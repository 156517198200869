import React from "react";
// react library for routing
import {Link} from "react-router-dom";
// reactstrap components
import {Nav, Navbar, NavbarBrand, NavItem, NavLink,} from "reactstrap";
import classnames from "classnames";
import {LanguageSwitcher} from "../LanguageSwitcher/LanguageSwitcher";

function IndexNavbar({logo}) {
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom navbar-light",
        )}
        container={'lg'}
      >
        {logo ? (
          <NavbarBrand {...navbarBrandProps}>

            <img src={require("assets/img/brand/logo.png")} width={'190'}/>
          </NavbarBrand>
        ) : null}
        <Nav className="ms-auto" navbar>
          <NavItem className={'d-flex align-items-center'}>
            <NavLink to="/auth/login" tag={Link}>
              <span className="nav-link-inner--text text-primary">Login</span>
            </NavLink>
          </NavItem>
          {/*
          <NavItem>
            <NavLink onClick={scrollToTargetDiv} tag={Link}>
              <span className="nav-link-inner--text text-primary">Register</span>
            </NavLink>
          </NavItem>
          */}
          <LanguageSwitcher/>
        </Nav>
      </Navbar>
    </>
  );
}

export default IndexNavbar;
