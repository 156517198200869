import {get} from './config/base';
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'clients/{clientId}/';

export const Platforms = {
  amazon_advertising_profiles: (clientId, params = null) =>
    get(urlParser('clients/{clientId}/amazon/advertising/profiles', clientId), {params: params})
      .then(handleResponse)
      .catch(handleError),
}
