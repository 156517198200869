import React, {useEffect, useState} from "react";
import {Reports} from "../../services/api/Reports";
import {apiErrorRaised, useApiErrorDispatch} from "../../context/api_error/ApiErrorIndex";
import {Button} from "reactstrap";
import {Link} from "react-router-dom";
import {ReportItem} from "./ReportItem";
import Paginator from "../Pagination/Paginator";
import {DateSelector} from "../DatePicker/DateSelector";
import {useAppState} from "../../context/app/AppContext";
import {subDays} from "date-fns";
import {Accounts} from "../../services/api/Accounts";

export const ReportsList = ({clientId, accountId}) => {
  const [isLoading, setLoading] = useState(false)
  const [reports, setReports] = useState([])
  const [pagination, setPagination] = useState({})
  const [offset, setOffset] = useState(0)
  const [limit, setLimit] = useState(12)
  const [filters, setFilters] = useState([])
  const appState = useAppState()
  const dispatchApiError = useApiErrorDispatch()
  const [range, setRange] = useState({
    startDate: subDays((new Date()).setHours(0, 0, 0, 0), 7),
    endDate: subDays((new Date()).setHours(0, 0, 0, 0), 1),
  })

  useEffect(() => {
    loadReports(limit, offset)
  }, [limit, offset, filters])

  const loadReports = () => {
    setLoading(true);
    Reports.index(clientId, accountId).then((r) => {
      setLoading(false);
      if (r?.status < 400) {
        setReports(r.data.reports);
        setPagination(r?.data.pagination)
      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }

  const handleClick = (e, offset, limit) => {
    e.preventDefault();
    setLimit(limit)
    setOffset(offset)
  }

  const handleRequestReports = () => {
    setLoading(true);
    Accounts.requestReports(appState.clientId, appState.accountId, range).then((r) => {
      setLoading(false);
      if (r?.status < 400) {

      } else {
        apiErrorRaised(dispatchApiError, r?.response)
      }
    });
  }

  return (
    <>
      <div className={'d-flex flex-column'}>
        <div className={'d-flex flex-row justify-content-between'}>
          <div className={'d-flex flex-row'}>
            <h1 className={'ms-4'}>Reports</h1>
          </div>
          <div className={'d-flex align-items-center'}>
            <div className={'d-flex flex-column justify-content-evenly mb-3'}>
              <Link to={`/admin/clients/${clientId}/accounts/${accountId}/reports/new`}>
                <Button color={'primary'} className={'me-1'}>New Report</Button>
              </Link>
            </div>
            <div className={'d-flex flex-column ms-3'}>
              <DateSelector range={range} setRange={setRange} isLoading={isLoading}/>
              <div className={'d-flex justify-content-end mt-2'}>
                <Button color={'primary'} onClick={handleRequestReports} className={'flex-grow-1'}>
                  {isLoading &&
                    <span className="spinner-border spinner-border-sm me-1"/>}
                  Request Reports
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={'mt-4 reports-grid-container'}>
        <div className={'reports-cards-list'}>
          {
            reports && reports.map((report, i) => {
              return (<ReportItem key={i} report={report}/>);
            })
          }
        </div>
      </div>
      <div className={'mt-3 mb-3'}>
        <Paginator
          pagination={pagination}
          handleClick={handleClick}
          offset={offset}
          limit={limit}
        />
      </div>
    </>
  )
}
