import {get, post, put} from "./config/base";
import {handleError, handleResponse} from "./config/response";
import {urlParser} from "./config/utils";

const url = 'clients/{clientId}/users/';

export const Users = {
  index: (clientId) =>
    get(`${urlParser(url, clientId)}`)
      .then(handleResponse)
      .catch(handleError),
  get: (id) =>
    get(`${url}${id}`)
      .then(handleResponse)
      .catch(handleError),
  put: (clientId, id, payload) =>
    put(`${urlParser(url, clientId)}invite/${id}`, payload)
      .then(handleResponse)
      .catch(handleError),
  upload: (payload) => {
    let formData = new FormData();
    formData.append('file', payload);
    return post(`${url}upload`, formData)
      .then(handleResponse)
      .catch(handleError)
  },
}
