export const names = [
  {
    'label': 'Sell Out Retail',
    'value': 'SELL_OUT',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Sell Out Manufacturing',
    'value': 'SELL_OUT_MANUFACTURING',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Sell In',
    'value': 'SELL_IN',
    'async': false,
    'snapshot': false,
  },
  {
    'label': 'Traffic',
    'value': 'TRAFFIC',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Sell In Open',
    'value': 'SELL_IN_OPEN',
    'async': false,
    'snapshot': true,
  },
  {
    'label': 'Sell Out Fresh Retail',
    'value': 'SELL_OUT_FRESH',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Sell Out Fresh Manufacturing',
    'value': 'SELL_OUT_FRESH_MANUFACTURING',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Sell Out Business Retail',
    'value': 'SELL_OUT_BUSINESS',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Sell Out Business Manufacturing',
    'value': 'SELL_OUT_BUSINESS_MANUFACTURING',
    'async': true,
    'snapshot': false,
  },
  {
    'label': 'Inventario Retail',
    'value': 'INVENTORY',
    'async': true,
    'snapshot': true,
  },
  {
    'label': 'Inventario Manufacturing',
    'value': 'INVENTORY_MANUFACTURING',
    'async': true,
    'snapshot': true,
  },
  {
    'label': 'Inventario Fresh Retail',
    'value': 'INVENTORY_FRESH',
    'async': true,
    'snapshot': true,
  },
  {
    'label': 'Inventario Fresh Manufacturing',
    'value': 'INVENTORY_FRESH_MANUFACTURING',
    'async': true,
    'snapshot': true,
  },
  /*{
    'label': 'Cesta de compra',
    'value': 'BASKET',
    'async': true,
    'snapshot': false,
  },*/
]
